import React, {useState} from "react";
import {Container} from "aurelia-dependency-injection";
import {DialogService} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";
import {createUseStyles} from "react-jss";
import useApiFetch, {useModelChoiceSearch} from "../api/use-fetch";
import {UniversalEntitySelect} from "../dialog/universal-entity-select";
import {currencies} from "../currency/model/currencies.model";

import {
    Col,
    ControlLabel,
    DatePicker,
    Dropdown,
    FlexboxGrid,
    FormControl,
    FormGroup,
    Icon,
    Input,
    InputGroup,
    InputPicker,
    Loader
} from "rsuite";

const i18n = Container.instance.get(I18N);
const dialogService = Container.instance.get(DialogService);

const useStyles = createUseStyles({
    inputPicker: {
        ".rs-form-vertical .rs-form-group &.rs-input-group": {
            width: "100%",
            display: "flex",
            "& > .rs-picker-input": {
                flexGrow: 1,
                width: "inherit",
                borderWidth: 0
            },
            "& > .rs-dropdown > .rs-dropdown-toggle": {
                paddingRight: "16px",
                "& > .rs-dropdown-toggle-caret": {
                    display: "none"
                }
            }
        }
    }
});

export const FormItem = ({label, field, accepter, col, ...props}) => {

    col = Object.assign({xs: 12, sm: 8, md: 6, lg: 4}, col ?? {});

    return (
        <FlexboxGrid.Item componentClass={Col} {...col}>
            <FormGroup controlId={field}>
                <ControlLabel>{label}</ControlLabel>
                <FormControl name={field} accepter={accepter} {...props}/>
            </FormGroup>
        </FlexboxGrid.Item>
    );
};

export const EntityPicker = ({modelId, conditions, value, valueMapper, ...props}) => {
    const [search, setSearch] = useState("");
    const {loading, items} = useModelChoiceSearch(modelId, search, conditions, value);
    const results = (value?.id ? 1 : 0) < (items || []).length;

    return (
        <InputPicker
            block
            data={valueMapper ? items.map(valueMapper) : items}
            onSearch={word => setSearch(word)}
            placement="auto"
            sort={() => ({label: a}, {label: b}) => a.localeCompare(b)}
            value={valueMapper ? value : value?.id ? {id: value.id, modelId: value.modelId} : null}
            searchBy={(keyword, label) => label.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())}
            renderValue={(_, item) => loading || undefined === item ? <Loader/> : item?.label}
            renderMenuItem={label => "" !== search ?
                <span dangerouslySetInnerHTML={{__html: label.replace(search, "<b>" + search + "</b>")}}/> : label}
            renderMenu={menu => !loading && results ? menu : (
                <div className="text-center">
                    {loading ? <Loader content="Suche läuft …"/> : "Keine Suchergebnisse"}
                </div>
            )}
            {...props}
        />
    );
};

export const EntityPickerWithSelect = ({modelId, conditions = {}, value, onChange, ...props}) => {
    const {loading, data: models} = useApiFetch("interface/implementations/" + modelId);
    const {inputPicker} = useStyles();

    const selectFromTable = (modelId, event) => dialogService
        .open({viewModel: UniversalEntitySelect, model: {selectModelId: modelId}})
        .whenClosed(({wasCancelled, output}) => wasCancelled || onChange(output[0], event));

    return (
        <InputGroup className={inputPicker}>
            <EntityPicker modelId={modelId} conditions={conditions} value={value} onChange={onChange} {...props}/>
            <Dropdown className="rs-input-group-btn" icon={<Icon icon="multiple-choice" classPrefix="sio-icon"/>}
                      placement="topEnd">
                {loading && <Dropdown.Item icon={<Loader/>}/>}
                {(models || []).map(({id, moduleId, label}) => {
                    const modelId = moduleId + "/" + id;
                    return (
                        <Dropdown.Item key={modelId} onClick={event => selectFromTable(modelId, event)}>
                            {i18n.tr(label)}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown>
        </InputGroup>
    );
};

export const CurrencyInput = ({name, value, defaultValue, onChange, ...props}) => {
    const currency = currencies[defaultValue?.currency ?? value?.currency ?? "EUR"];
    const factor = 10 ** currency.decimal_digits;
    let amount = value?.amount ?? defaultValue?.amount;

    if (amount) {
        amount /= factor;
    }

    // Note: Not using InputNumber here to allow entering decimal comma
    return (
        <InputGroup>
            <Input
                type="number"
                className="text-right"
                value={amount}
                style={{width: "100%"}}
                step={10 ** -currency.decimal_digits}
                lang="de"
                onChange={(amount, event) => {
                    onChange({amount: Math.round(amount * factor), currency: currency.code}, event)
                }}
                {...props}
            />
            <InputGroup.Addon>{currency.symbol}</InputGroup.Addon>
        </InputGroup>
    );
}
export const DateSelect = props => (
    <DatePicker block format="DD.MM.YYYY" placement="topStart" {...props}/>
);
