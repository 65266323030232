import { inject, bindable, customElement, bindingMode, LogManager } from 'aurelia-framework';
import {Client} from '../api/client';

const logger = LogManager.getLogger('history-display');

@customElement('sio-history-display')
@inject(Client)
export class HistoryDisplay
{
    @bindable object;

    @bindable diff;

    diffItems = [];

    bind()
    {
        console.log('diff', this.diff);
    }

    constructor(client)
    {
        this.client = client;
    }

    async bind()
    {
        this.columns = [];

        this.list = await this.client.get(this.object.modelId + '/list', 60);

        this.diffItems = [];

        _.each(this.diff, (diff, property) => {

            let column = _.find(this.list.columns, (o) => { return o.property === property});

            if (!column) {
                logger.error('Column not found ' + property);
                return;
            }

            this.diffItems.push(
                {
                    column: column,
                    diff: diff,
                    property: property,
                }
            );
        });
    }

    _getObject(property, value) {
        let obj = {};
        obj[property] = value;
        return obj;
    }
}
