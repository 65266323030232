import {inject, LogManager, noView} from 'aurelia-framework';
import {SortableFormLayout} from "./sortable-form-collection";
import {BindingSignaler} from 'aurelia-templating-resources';
import {EventAggregator} from 'aurelia-event-aggregator';
import {I18N} from 'aurelia-i18n';
import {Client} from "../../api/client";
import {DialogService} from 'aurelia-dialog';

const logger = LogManager.getLogger('SortableDaysCollection');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@noView
@inject(BindingSignaler, EventAggregator, Client, I18N, DialogService)
export class SortableChildReductionCollection extends SortableFormLayout {
    constructor(signaler, ea, client, i18n, DialogService) {
        super(signaler, ea, client, i18n, DialogService);
    }

    _getControlUID() {
        return 'sortable-days-collection-' + this.config.fullProperty;
    }

    getTabTitle(fieldToFind) {
        const index = this.getFieldIndex(fieldToFind, this.fields);
        const title = this._getTabTitle(index);

        return index + ' ' + (title || '<Neu>');
    }

    _getTabTitle(index) {

        let childReductionLines = this.config.formService.getValue().childReductionLines;

        childReductionLines.forEach(childReductionLine => {
            let title = [];
            let ageTo = childReductionLine.ageTo;
            if (ageTo) {
                title.push('Age: ' + ageTo);
            }

            let childTo = childReductionLine.childTo;
            if (childTo) {
                title.push('CH: ' + childTo);
            }

            let adults = childReductionLine.adults;
            if (adults) {
                title.push('VZ: ' + adults);
            }


            childReductionLine.title = (title.length > 0) ? title.join(' ') : ' ';
        });

        logger.debug('Days', childReductionLines);

        if (null == childReductionLines[index]) {
            return '';
        }

        return childReductionLines[index].title;
    }
}
