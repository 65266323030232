import {noView, inject, LogManager} from 'aurelia-framework';
import {SortableFormLayout} from "./sortable-form-collection";
import {BindingSignaler} from 'aurelia-templating-resources';
import {EventAggregator} from 'aurelia-event-aggregator';
import {I18N} from 'aurelia-i18n';
import {Client} from "../../api/client";
import * as moment from "moment-timezone";
import { DialogService } from "aurelia-dialog";

const logger = LogManager.getLogger('SortableDaysCollection');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@noView
@inject(BindingSignaler, EventAggregator, Client, I18N, DialogService)
export class SortableTimetableCollection extends SortableFormLayout
{
    constructor(signaler, ea, client, i18n, DialogService)
    {
        super(signaler, ea, client, i18n, DialogService);
        this.onFormValueChanged2Subscription = this.ea.subscribe('sio_form_value_changed', response => {

            if (response.field && (response.field.property == 'fromDate' || response.field.property == 'onSameDay')) {

                this.signaler.signal('sio__sortable-collection__reload-tab-titles');
            }
        });
    }

    detached()
    {
        this.onFormValueChanged2Subscription.dispose();

        super.detached();
    }

    _getControlUID()
    {
        return 'sortable-timetable-collection-' + this.config.fullProperty;
    }

    getTitle(index)
    {
        let value = this.config.formService.getValue();

        if (value.fromDate) {

            let modification = 0;

            for (let i = 0; i < index; i++) {
                let item = value.days[i];

                if (!item.onSameDay) {
                    modification++;
                }
            }

            if (value.days[index].onSameDay) {
                modification--;
            }

            return moment(value.fromDate).add(modification, 'days').format('L');
        } else {
            return index + 1 + '. Tag';
        }

        return index;
    }
}
