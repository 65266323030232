import {inject, LogManager} from 'aurelia-framework';
import {I18N} from 'aurelia-i18n';
import moment from "moment";
import numeral from "numeral";

const logger = LogManager.getLogger('i18n');

@inject(I18N)
export class LocaleService
{
    contentLocales;
    contentLocale;

    constructor(i18n)
    {
        this.i18n = i18n;
    }

    setLocale(user)
    {
        if (user?.settings?.userSettings?.locale) {
            const locale = user.settings.userSettings.locale;

            if (this.i18n.getLocale() !== locale) {
                logger.debug('Set locale from settings', locale);

                this.i18n.setLocale(locale);
            }

            if (locale !== 'en') {
                require('moment/locale/' + locale);
                require('numeral/locales/' + locale);
            }

            moment.locale(locale);
            numeral.locale(locale);

        } else {
            //TODO Upload determined browser locale to user settings?
            logger.debug('No locale setting found');
        }

        this.contentLocales = user.instance?.settings?.instanceGeneral?.contentLocales;
        this.contentLocale = this.contentLocales?.[0] || 'de';
    }

    setContentLocale(contentLocale)
    {
        this.contentLocale = contentLocale;
    }
}
