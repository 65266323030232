import {inject, LogManager} from "aurelia-framework";
import * as _ from "lodash";
import {EventAggregator} from "aurelia-event-aggregator";

export const UnsavedChanges = {
    changes: [],
    ignore: false
};

const logger = LogManager.getLogger('UnsavedChangesPrevent');
//logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

let initialized = false;

// Initialize only once
if (!initialized) {
    initialized = true;

    window.addEventListener('beforeunload', (event) => {

        // Don't ask anything on login page
        if (UnsavedChanges.ignore || '/login' === location.pathname) {
            return;
        }

        // Ask before leave when some changes were made and not saved
        if (0 < UnsavedChanges.changes.length) {
            event.returnValue = '';
            event.preventDefault();
        }
    });
}

@inject(
    EventAggregator
)
export class UnsavedChangesPrevent {
    constructor(
        ea
    ) {
        this.ea = ea;
    }

    async attached() {
        this.registerUnsavedChangesSubscription = this.ea.subscribe('sio_register_unsaved_changes', (event) => {
            UnsavedChangesPrevent.registerUnsavedChanges(event.changesKey);
        });

        this.unregisterUnsavedChangesSubscription = this.ea.subscribe('sio_unregister_unsaved_changes', (event) => {
            UnsavedChangesPrevent.unregisterUnsavedChanges(event.changesKey);
        });

        this.formValueChangedSubscription = this.ea.subscribe('sio_form_value_changed', (event) => {
            if (event.form.disableLocalStorage) {
                this.ea.publish('sio_register_unsaved_changes', {changesKey: event.form});
            }
        });
    }

    detached() {
        this.registerUnsavedChangesSubscription.dispose();
        this.unregisterUnsavedChangesSubscription.dispose();
        this.formValueChangedSubscription.dispose();
    }

    static cleanChanges()
    {
        logger.debug('Cleaned unsaved changes list');

        UnsavedChanges.changes = [];
    }

    static registerUnsavedChanges(key)
    {
        if (UnsavedChangesPrevent.findChangeByKey(key) !== -1) {
            return;
        }

        UnsavedChanges.changes.push(key);

        logger.debug('Changed forms list extended', _.clone(UnsavedChanges.changes));
    }

    static findChangeByKey(key)
    {
        if (key == null) {
            return -1;
        }

        if (UnsavedChanges.ignore || !UnsavedChanges.changes.length) {
            return -1;
        }

        if (null != key.bulkFormConfig) {
            // If key is bulk form, then verify it by its config instead of key reference

            return UnsavedChanges.changes.findIndex(
                oldChangedForm => key.bulkFormConfig === oldChangedForm.bulkFormConfig
            );
        }

        return UnsavedChanges.changes.indexOf(key);
    }

    static unregisterUnsavedChanges(key)
    {
        let changeId = UnsavedChangesPrevent.findChangeByKey(key);

        if (changeId > -1) {
            UnsavedChanges.changes.splice(changeId, 1);

            logger.debug('Changed forms list reduced', _.clone(UnsavedChanges.changes));
        }
    }
}
