import {inject} from 'aurelia-framework';
import * as _ from 'lodash';
import * as $ from 'jquery';
import {Client} from '../../api/client';
import {UserClient} from '../../api/user-client';
import {BindFormValuesService} from './bind-form-values.service';

@inject(
    Client,
    UserClient
)
export class DataLoader
{
    constructor(
        client,
        userClient
    ) {
        this.client = client;
        this.userClient = userClient;
    }

    /*public*/ async get(data, config, params)
    {
        data = await this._loadData(data, config, params);
        data = this._addDefaultValuesToData(data, config, params);

        let bindings = params.bindValues;
        let bindingContext = await this._buildBindingContext(config, params);

        data = BindFormValuesService.bindValues(data, bindings, bindingContext);

        return data;
    }

    /*private*/ async _buildBindingContext(config, params)
    {
        return {
            '@config': config, // Form configuration
            '@params': params, // Form parameters
            '@user': this.userClient.user // Current user
        };
    }

    /*private*/ _loadData(data, config, params)
    {
        if (params.modelId && params.modelId !== config.modelId) {
            return Promise.resolve({});
        }

        if (config.dataUrl) {

            return this.client
                .get(config.dataUrl + '?' + $.param(params), 60)
                .then(config => {
                    return _.cloneDeep(config);
                });

        } else if (_.isString(data) || _.isArray(data)) {

            let append = '';

            if (config.embeds && config.embeds.length > 0) {
                append = '?' + $.param({embeds: config.embeds});
            }

            return this.client
                .get(config.modelId + '/' + (_.isArray(data) ? data[0] : data) + append, 60)
                .then(config => {
                    config = _.cloneDeep(config);
                    config.id = data;
                    return config;
                });
        } else if (_.isObject(data)) {
            return Promise.resolve(_.cloneDeep(data));
        } else {
            return Promise.resolve({});
        }
    }

    /*private*/ _addDefaultValuesToData(data, config, params)
    {
        // @Todo this is double implementation to form-structure
        // @TODO This method implements insertion of default values only for 1st level of hierarchy.
        // This must be extended and override complicated processing inside FormStructure.
        //
        // This way data will be separated from representation, and it will be easier to perform
        // operations on default values before making actual assignment to form controls

        _.each(config.fields, (field) => {
            if (data[field.property] !== undefined) return true;

            if (field.context && params.contextObjectRef != null &&
                (
                    ((field.modelId) && field.modelId === params.contextObjectRef.modelId) ||
                    (field.implementations && field.implementations.includes(params.contextObjectRef.modelId))
                )
            ) {
                if (field.multiple === true) {
                    data[field.property] = params.contextObjectRef ? [params.contextObjectRef] : [];
                } else {
                    data[field.property] = params.contextObjectRef;
                }
                field.hidden = true;
                return true;
            }

            data[field.property] = field.default;
        });

        return data;
    }
}
